.find-us {
  background: var(--brand-purple-light-max);
  overflow-x: hidden;

  .find-us-container {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 5rem;

    .find-us-info {
      flex: 1;

      .contact-detail {
        font-size: 1.20em;
        margin-bottom: 1rem;
        
        strong {
          display: block;
          margin-bottom: 0.25rem;
        }

        .phone-link {
          color: inherit;
          text-decoration: none;
          transition: color 0.3s ease;
          
          &:hover {
            color: blue;
            text-decoration: underline;
          }
        }
      }
    }

    .find-us-timetables {
      flex: 2 1;
      padding: 2rem;
      border-radius: 10px;
      width: 100%;
      align-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      background-color: white;

  
      h3 {
        margin-bottom: 1rem;
        font-size: 32px;
        margin-bottom: 20px;
      }
  
      .timetable {
        width: 100%;
        border-collapse: collapse;
  
        th, td {
          padding: 1rem;
          text-align: left;
          border-bottom: 1px solid #ddd;
        }
  
        th {
          background-color: var(--bg-light-secondary);
          color: white;
        }
  
        tbody tr:last-child td {
          border-bottom: none;
        }
      }
    }

    .find-us-map {
      width: 100%;
      display: flex;
      justify-content: right;
      margin-bottom: 2rem;

      @media (max-width: 768px) {
        justify-content: center;
      }

      iframe {
        width: 100%;
        height: 450px;
        border-radius: 10px;
        border: none;

        @media (max-width: 768px) {
          height: 300px;
          width: 100%;
        }
      }
    }
  }
}
